<template>
  <div class="header" @click="$router.push('/')">
    <img alt="logo" src="../../assets/logo/color.svg" />
    <router-link to="/auth/signin" class="login-btn">{{ $t("login.button") }}</router-link>
  </div>
</template>

<script>
export default {
  name: "TermsHeader",
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0 50px;
  margin: 20px 0;

  img {
    width: 130px;
  }
}
.login-btn {
  border: 1px solid $primarylight3;
  padding: 8px 40px;
  color: $primarylight3;
  text-decoration: none;
}
</style>
