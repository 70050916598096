<template>
  <div class="terms-layout">
    <TermsHeader />
    <div class="terms-wrap">
      <slot />
    </div>
    <FooterSection />
  </div>
</template>

<script>
import FooterSection from "@/components/Layout/Footer";

import TermsHeader from "./TermsHeader";

export default {
  name: "TermsLayout",
  components: {
    TermsHeader,
    FooterSection,
  },
};
</script>

<style lang="scss">
.terms-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.terms-wrap {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  /*display: flex;
  align-items: flex-start;*/

  strong {
    font-size: 24px;
  }
}
</style>
