import { apiRequest } from "./apiRequest";

const fetchTerms = async () => {
  const { data } = await apiRequest.get(`/terms`);
  return data;
};

const fetchConsent = async () => {
  const { data } = await apiRequest.get(`/terms/consent`);
  return data;
};

export const TermsAPI = {
  fetchTerms,
  fetchConsent,
};
