<template>
  <div class="title-block">
    <h1>{{ title }}</h1>
    <h3>{{ subtitle }}</h3>
  </div>
</template>

<script>
export default {
  name: "TermsTitle",
  props: ["title", "subtitle"],
};
</script>

<style lang="scss" scoped>
.title-block {
  position: relative;
  z-index: 1;
  padding: 30px 0;
  margin-bottom: 70px;

  &:before {
    content: "";
    width: 150vw;
    position: absolute;
    left: -100%;
    top: 0;
    height: 100%;
    background-color: #fafafc;
    z-index: -1;
  }

  h1 {
    font-size: 42px;
    line-height: 51px;
    text-transform: initial;
  }
  h3 {
    color: $primarylight3;
    font-size: 22px;
    line-height: 27px;
    margin-top: 18px;
  }
}
</style>
