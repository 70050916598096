<template>
  <nav class="toc">
    <p class="toc__title">{{ $t("general.tableOfContents") }}:</p>
    <ul class="toc__list">
      <li v-for="item in items" :key="item" @click="scrollToAnchor(item)" class="toc__list__item">{{ item }}</li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "ContentsTable",
  props: ["items", "textRef"],
  data() {
    return {
      anchors: null,
    };
  },
  methods: {
    scrollToAnchor(anchor) {
      this.anchors[anchor].scrollIntoView();
    },
  },
  updated() {
    if (this.textRef && !this.anchors) {
      this.anchors = Array.from(this.textRef.getElementsByTagName("h2")).reduce((a, e) => {
        a[e.innerText] = e;
        return a;
      }, {});
    }
  },
};
</script>

<style lang="scss" scoped>
.toc {
  margin: 230px 70px 0 0;
  width: 200%;
  color: #85878c;

  &__title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &__list {
    list-style: none;
    padding: 0;

    &__item {
      margin-bottom: 10px;
      cursor: pointer;

      &:hover {
        color: $primary;
      }
    }
  }
}
</style>
